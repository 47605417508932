import SignIn from '@/views/SignIn'

export default {
  name: 'sign_in',
  path: '/sign_in',
  component: SignIn,
  meta: {
    track: 'View Sign In',
  },
}
