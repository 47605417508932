import SignUp from '@/views/SignUp'

export default {
  name: 'sign_up',
  path: '/sign_up',
  component: SignUp,
  meta: {
    track: 'View Sign Up',
  },
}
