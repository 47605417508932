<template>
  <div ref="recaptcha"></div>
</template>

<script>
import { ref, onMounted } from 'vue'

const RECAPTCHA_SRC = 'https://www.google.com/recaptcha/api.js'

export default {
  props: ['siteKey'],
  emits: ['complete'],
  setup(props, { emit }) {
    const recaptcha = ref(null)
    const { siteKey } = props

    const render = () => {
      window.grecaptcha.render(recaptcha.value, {
        'sitekey': siteKey,
        'callback': token => emit('complete', token),
        'expired-callback': () => emit('expired'),
      })
    }

    onMounted(() => {
      if (!window.grecaptcha) {
        new Promise(resolve => {
          window.onRecaptchaReady = function() {
            resolve()
          }

          const script = document.createElement('script')
          script.setAttribute('src', `${RECAPTCHA_SRC}?onload=onRecaptchaReady&render=explicit`)
          script.defer = true
          document.head.appendChild(script)
        }).then(render)
      } else {
        render()
      }
    })

    return {
      recaptcha,
    }
  }
}
</script>
