<template>
  <span :class="{ [colorClass]: colored }">{{ formattedValue }}</span>
</template>

<script>
import { computed, toRefs } from 'vue'
import { formatCurrency } from '@/utils/helpers'

export default {
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    colored: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const { value } = toRefs(props)

    const colorClass = computed(() => {
      if (value.value > 0.0) {
        return 'text-green-600'
      } else if (value.value < 0.0) {
        return 'text-red-600'
      } else {
        return 'text-gray-600'
      }
    })

    return {
      formattedValue: computed(() => formatCurrency(value.value)),
      colorClass,
    }
  },
}
</script>
