import { onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'

export function useDatesSubscriber(handler) {
  const store = useStore()
  let unsubscribe = null

  onMounted(() => {
    unsubscribe = store.subscribe(mutation => {
      if (mutation.type === 'dates/setPreset' || mutation.type === 'dates/setCustom') {
        handler(store)
      }
    })
  })

  onUnmounted(() => {
    if (unsubscribe) {
      unsubscribe()
    }
  })
}
