<template>
  <div class="px-4 py-5 sm:p-6">
    <dt class="text-base font-normal text-gray-900">
      {{ title }}
    </dt>
    <dd class="mt-1 flex justify-between items-center md:block lg:flex">
      <div class="text-2xl font-semibold">
        <slot></slot>
      </div>
      <div>
        <slot name="secondary"></slot>
      </div>
    </dd>
  </div>
</template>

<script>
export default {
  props: ['title'],
}
</script>
