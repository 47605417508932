<template>
  <div v-if="modelValue" class="w-full bg-gray-100 rounded-lg p-4 flex items-center justify-between">
    <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
    <div class="flex-grow ml-4 min-w-0 w-0">
      <p class="text-sm text-gray-600 truncate">{{ filename }}</p>
    </div>
    <button class="flex-shrink-0 ml-2 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:text-green-500" @click="remove">
      <XIcon class="h-6 w-6" aria-hidden="true" />
      <span class="sr-only">Remove attachment</span>
    </button>
  </div>
  <div v-else class="flex items-center justify-between p-4 border-2 border-gray-300 border-dashed rounded-lg">
    <PaperClipIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
    <label for="attachment" class="ml-4 flex-grow cursor-pointer font-medium text-sm text-green-500 hover:text-green-400 focus-within:outline-none focus-within:underline">
      <span>Upload a file</span>
      <input type="file" :value="modelValue" accept="image/*,.pdf" id="attachment" class="sr-only" @change="change" />
    </label>
    <p class="ml-2 text-xs text-gray-500">
      PDF, PNG, JPG, GIF
    </p>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue'
import { PaperClipIcon } from '@heroicons/vue/outline'
import PDFIcon from 'images/icn-pdf.svg'
import { basename } from '@/utils/helpers'
import { XIcon } from '@heroicons/vue/outline'

export default {
  components: {
    PaperClipIcon,
    XIcon,
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props)

    const filename = computed(() => {
      if (!modelValue.value) {
        return null
      } else if (modelValue.value instanceof File) {
        return modelValue.value.name
      } else {
        return basename(modelValue.value)
      }
    })

    const remove = (event) => {
      emit('update:modelValue', null)
    }

    const change = (event) => {
      const { target: { files } } = event

      if (files.length > 0) {
        emit('update:modelValue', files[0])
      } else {
        remove()
      }
    }

    return {
      change,
      remove,
      filename,
    }
  }
}
</script>
