<template>
  <tr
    class="amo-selectable even:bg-white odd:bg-gray-50 hover:bg-green-50"
    tabindex="0"
    @click="show(income)"
    @keydown.enter.stop="show(income)"
  >
    <td :class="cellClass" class="whitespace-nowrap w-px">
      {{ formattedDate }}
    </td>
    <td :class="cellClass" class="max-w-0 truncate">
      {{ income.name }}
    </td>
    <td :class="cellClass" class="hidden md:table-cell">
      {{ income.memo }}
    </td>
    <td :class="cellClass" class="whitespace-nowrap w-px text-right">
      {{ formattedAmount }}
    </td>
    <td :class="cellClass" class="text-right whitespace-nowrap w-px">
      <div class="flex justify-end items-center">
        <a :href="income.attachment" v-if="income.attachment" @click.stop class="block flex-shrink text-gray-400 hover:text-gray-500 focus:outline-none focus:text-green-500">
          <span class="sr-only">Download</span>
          <PaperClipIcon class="h-6 w-6" aria-hidden="true" />
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import { toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { PaperClipIcon } from '@heroicons/vue/outline'
import { formatCurrency, formatDate } from '@/utils/helpers'

export default {
  components: {
    PaperClipIcon,
  },
  props: ['income'],
  setup(props) {
    const { income } = toRefs(props)
    const router = useRouter()

    const cellClass = computed(() => ['amo-cell', income.value.cleared ?  'amo-cell--cleared' : 'amo-cell--uncleared'])

    const show = (income) => {
      router.push({
        name: 'showIncome',
        params: {
          incomeId: income.id,
        }
      })
    }

    return {
      cellClass,
      formattedDate: computed(() => formatDate(income.value.date)),
      formattedAmount: computed(() => formatCurrency(income.value.amount)),
      show,
    }
  }
}
</script>
