import api from '@/api'

const state = () => ({
  loaded: false,
  all: [],
})

const getters = {}

const actions = {
  getAll ({ commit, rootState }) {
    const { start, end } = rootState.dates

    api.getStays({ start, end }).then(stays => {
      commit('setAll', stays)
    })
  },
}

const mutations = {
  setAll(state, stays) {
    state.loaded = true
    state.all = stays
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
