import axios from 'axios'
import store from '@/store'

const csrfToken = document.querySelector('meta[name="csrf-token"]') || {content: 'no-csrf-token'}

export const client = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'X-CSRF-Token': csrfToken.content,
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
})

client.interceptors.request.use(
	config => {
		const token = store.state.auth.token

		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`
		}

		return config
	},
	error => Promise.reject(error)
);

export const authenticate = (email, password) => client.post('/authenticate', { email, password }).then(response => response.data)
export const createAccount = (data) => client.post('/account', data).then(response => response.data)
export const getAccount = () => client.get('/account').then(response => response.data)
export const getCategories = () => client.get('/categories').then(response => response.data)
export const getExpense = (id) => client.get(`/expenses/${id}`).then(response => response.data)
export const getExpenses = (params) => client.get('/expenses', { params }).then(response => response.data)
export const createExpense = (data) => client.post('/expenses', data)
export const updateExpense = (id, data) => client.patch(`/expenses/${id}`, data)
export const destroyExpense = (id) => client.delete(`/expenses/${id}`)
export const removeExpenseAttachment = (id) => client.delete(`/expenses/${id}/attachment`)
export const createExpenseMileage = (data) => client.post('/expenses/mileage', data)
export const getIncome = (id) => client.get(`/incomes/${id}`).then(response => response.data)
export const getIncomes = (params) => client.get('/incomes', { params }).then(response => response.data)
export const createIncome = (data) => client.post('/incomes', data)
export const updateIncome = (id, data) => client.patch(`/incomes/${id}`, data)
export const destroyIncome = (id) => client.delete(`/incomes/${id}`)
export const removeIncomeAttachment = (id) => client.delete(`/incomes/${id}/attachment`)
export const getStay = (id) => client.get(`/stays/${id}`).then(response => response.data)
export const getStays = (params) => client.get('/stays', { params }).then(response => response.data)
export const createStay = (data) => client.post('/stays', data)
export const updateStay = (id, data) => client.patch(`/stays/${id}`, data)
export const destroyStay = (id) => client.delete(`/stays/${id}`)
export const incomeAndLossReport = (params) => client.get(`/reports/income_and_loss`, { params }).then(response => response.data)

export default {
  authenticate,
  createAccount,
  getAccount,
  getCategories,
  getExpense,
  getExpenses,
  createExpense,
  updateExpense,
  destroyExpense,
  removeExpenseAttachment,
  createExpenseMileage,
  getIncome,
  getIncomes,
  createIncome,
  updateIncome,
  destroyIncome,
  removeIncomeAttachment,
  getStay,
  getStays,
  createStay,
  updateStay,
  destroyStay,
  incomeAndLossReport,
}
