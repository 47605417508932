<template>
	<div class="px-4 sm:px-6">
    <form enctype="multipart/form-data" @submit.prevent="submit">
      <h2 class="amo-h2 mb-6">Add Stay</h2>

      <Errors v-if="errors.length > 0" title="Oops, looks like you need to fix something:" :errors="errors" class="mb-6" />

      <dl class="divide-y divide-gray-200">
        <div class="amo-details">
          <dt><label for="starts_on">Starts</label></dt>
          <dd>
            <input v-model="stay.starts_on" type="date" id="starts_on" class="amo-input w-48" />
          </dd>
        </div>

        <div class="amo-details">
          <dt><label for="ends_on">Ends</label></dt>
          <dd>
            <input v-model="stay.ends_on" type="date" id="ends_on" class="amo-input w-48" />
          </dd>
        </div>

        <div class="amo-details">
          <dt><label for="name">Name</label></dt>
          <dd>
            <input v-model="stay.name" type="text" id="name" class="amo-input w-48" />
          </dd>
        </div>

        <div class="amo-details">
          <dt><label for="classification">Classification</label></dt>
          <dd>
            <select v-model="stay.classification" id="classification" name="classification" class="amo-input w-40 sm:w-48">
              <option value="personal">Personal</option>
              <option value="rental">Rental</option>
              <option value="maintenance">Maintenance</option>
            </select>
          </dd>
        </div>

        <div class="amo-details">
          <dt><label for="memo">Memo</label></dt>
          <dd>
            <textarea rows="2" v-model="stay.memo" id="memo" class="amo-input w-48" />
          </dd>
        </div>
      </dl>

      <div class="py-5">
        <div class="flex justify-end">
          <button @click="close" type="button" class="amo-btn-secondary">
            Cancel
          </button>
          <button type="submit" class="ml-3 inline-flex justify-center amo-btn">
            Save
          </button>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import Errors from '@/components/Errors'
import { createStay } from '@/api'
import store from '@/store'
import mixpanel from '@/utils/mixpanel'

export default {
  components: {
    Errors,
  },
  beforeRouteEnter() {
    store.commit('panel/open')
  },
  beforeRouteLeave() {
    store.commit('panel/close')
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const stay = reactive({
      starts_on: null,
      ends_on: null,
      name: '',
      classification: 'rental',
      memo: '',
    })

    const errors = ref([])

    return {
      stay,
      errors,
      close: () => router.push({ name: 'stays' }),
      submit: () => {
        const data = new FormData()

        data.append("starts_on", stay.starts_on)
        data.append("ends_on", stay.ends_on)
        data.append("name", stay.name)
        data.append("classification", stay.classification)
        data.append("memo", stay.memo)

        createStay(data)
          .then(() => {
            mixpanel.track('Add Stay')
            store.dispatch('stays/getAll')
            router.push({ name: 'stays' })
          })
          .catch(error => {
            const { response } = error

            if (response) {
              errors.value = response.data.errors
            }
          })
      }
    }
  }
}
</script>
