<template>
  <div class="mt-10 bg-white shadow rounded-lg overflow-hidden">
    <ul class="divide-y divide-gray-200">
      <li v-for="stay in stays" :key="stay.id">
        <StayItem :stay="stay" />
      </li>
    </ul>
  </div>
</template>

<script>
import StayItem from './StayItem'

export default {
  components: {
    StayItem,
  },
	props: ['stays'],
}
</script>
