<template>
  <Overview v-if="loaded">
    <OverviewItem title="Occupancy">
      <template #default>
        <span title="Of total nights">{{ occupancyTotal }}</span>
      </template>

      <template #secondary>
        <Badge color="gray" title="Of available nights">{{ occupancyAvailable }} of available</Badge>
      </template>

    </OverviewItem>

    <OverviewItem title="Rental Nights">
      <template #default>
        <span class="text-indigo-600">{{ totalRentalNights }}</span>
      </template>

      <template #secondary>
        <Badge color="indigo">{{ averageNights.toFixed(1) }} nights per stay</Badge>
      </template>
    </OverviewItem>

    <OverviewItem title="Bookings">
      <template #default>
        <Currency :value="totalRentalIncome" colored />
      </template>

      <template #secondary>
        <Badge :color="colorByValue(averageNightlyIncome)">
          <Currency :value="averageNightlyIncome" />&nbsp;/ night
        </Badge>
      </template>
    </OverviewItem>
  </Overview>
  <Overview v-else>
    <OverviewPlaceholder />
    <OverviewPlaceholder />
    <OverviewPlaceholder />
  </Overview>
</template>

<script>
import { computed, toRefs } from 'vue'
import { useStore } from 'vuex'
import dayjs from 'dayjs'

import { Overview, OverviewItem, OverviewPlaceholder } from '@/components/Overview'
import Currency from '@/components/Currency'
import Badge from '@/components/Badge'
import { colorByValue, formatPercent } from '@/utils/helpers'
import { DateRange } from '@/utils/date_range'

export default {
  components: {
    Badge,
    Currency,
    Overview,
    OverviewItem,
    OverviewPlaceholder,
  },
  props: ['stays', 'loaded'],
	setup(props) {
    const { stays } = toRefs(props)
    const store = useStore()

    const sumOf = (attr) => {
      return (accum, stay) => accum + parseFloat(stay[attr])
    }

    const dateRange = computed(() => {
      return new DateRange(dayjs(store.state.dates.start), dayjs(store.state.dates.end))
    })

    const dateMap = computed(() => {
      const map = {}

      for (const date of dateRange.value.nights()) {
        map[date.format('YYYY-MM-DD')] = null
      }

      stays.value.forEach(stay => {
        let stayDates = new DateRange(dayjs(stay.starts_on), dayjs(stay.ends_on))

        for (const date of stayDates.nights()) {
          map[date.format('YYYY-MM-DD')] = stay.classification
        }
      })

      return map
    })

    const nights = computed(() => {
      const counts = {
        rental: 0,
        maintenance: 0,
        personal: 0,
        total: 0,
      }

      for (const classification of Object.values(dateMap.value)) {
        if (classification) {
          counts[classification] += 1
        }
        counts.total += 1
      }

      return counts
    })

    const rentals = computed(() => stays.value.filter(stay => stay.classification == 'rental'))

    const occupancyTotal = computed(() => {
      const { rental, total } = nights.value

      if (total == 0) {
        return 0.0
      }

      return rental / total
    })

    const occupancyAvailable = computed(() => {
      const { rental, maintenance, personal, total } = nights.value

      const available = total - (personal + maintenance)

      if (available <= 0) {
        return 0.0
      }

      return rental / available
    })

    const totalRentalIncome = computed(() => rentals.value.reduce(sumOf('incomes'), 0.0))
    const totalRentalStays = computed(() => rentals.value.length)
    const totalRentalNights = computed(() => rentals.value.reduce(sumOf('nights'), 0))

    const averageNightlyIncome = computed(() => {
      if (totalRentalNights.value == 0) {
        return 0.0
      }
      return totalRentalIncome.value / totalRentalNights.value
    })

    const averageNights = computed(() => {
      if (totalRentalStays.value == 0) {
        return 0.0
      }
      return totalRentalNights.value / totalRentalStays.value
    })

    return {
      rentals,
      occupancyAvailable: computed(() => formatPercent(occupancyAvailable.value, 1)),
      occupancyTotal: computed(() => formatPercent(occupancyTotal.value, 1)),
      averageNights,
      totalRentalNights,
      totalRentalIncome,
      averageNightlyIncome,
      colorByValue,
      formatPercent,
    }
  }
}
</script>
