<template>
  <div class="px-4 py-5 sm:p-6">
    <dt>
      <div class="placeholder w-40" />
    </dt>
    <dd class="mt-1 flex justify-between items-center md:block lg:flex">
      <div class="placeholder w-48" />
      <div class="placeholder w-24" />
    </dd>
  </div>
</template>

<style scoped>
.placeholder {
  @apply inline-block bg-gray-200 h-5;
}
</style>
