import Reports from '@/views/Reports/Main'
import ReportsHeader from '@/views/Reports/Header'

export default {
  name: 'reports',
  path: '/reports',
  components: {
    default: Reports,
    Header: ReportsHeader,
  },
  meta: {
    track: 'View Reports',
  },
}
