<template>
  <div class="my-10 flex justify-end">
    <DateRangeInput />
  </div>

  <div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
    <div class="px-4 pt-5 sm:px-6">
      <nav class="-mb-px flex space-x-8" aria-label="Reports">
        <a v-for="report in reports"
           href="#"
           :key="report.name"
           :class="[report === current ? 'border-green-500 text-green-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-md']"
           :aria-current="report === current ? 'page' : undefined"
           @click="current = report"
         >
          {{ report.name }}
        </a>
      </nav>
    </div>
    <div class="px-4 py-5 sm:p-6">
      <component :is="current.component"></component>
    </div>
  </div>
</template>

<script>
import IncomeAndLossReport from './IncomeAndLossReport'
import DateRangeInput from '@/components/DateRangeInput'

const reports = [
  { name: 'Income and Loss', component: IncomeAndLossReport },
]

export default {
  components: {
    DateRangeInput,
  },
  setup() {
    const current = reports[0]

    return {
      current,
      reports,
    }
  },
}
</script>
