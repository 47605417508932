<template>
	<Listbox :modelValue="modelValue" @update:modelValue="$emit('update:modelValue', $event)" as="div">
    <div class="mt-1 relative">
      <ListboxButton class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm">
        <span v-if="selected" class="w-full inline-flex truncate">
          <span class="truncate">{{ selected.name }}</span>
          <span class="ml-2 truncate text-gray-500">{{ selected.start_date }}</span>
        </span>
        <span v-else class="w-full inline-flex truncate">
          <span class="truncate text-gray-500">None</span>
        </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="stay in stays" :key="stay.id" :value="stay.id" v-slot="{ active, selected }">
            <li :class="[active ? 'text-white bg-green-500' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
              <div class="flex">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate']">
                  {{ stay.name }}
                </span>
                <span :class="[active ? 'text-green-100' : 'text-gray-500', 'ml-2 truncate']">
                  {{ stay.starts_on }}
                </span>
              </div>

              <span v-if="selected" :class="[active ? 'text-white' : 'text-green-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { computed, ref, toRefs, onMounted } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { getStays } from '@/api'

export default {
	components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  props: ['modelValue'],
  setup(props) {
    const { modelValue } = toRefs(props)
    const stays = ref([])
    const selected = computed(() => stays.value.find(e => e.id == modelValue.value))

    onMounted(async () => {
      stays.value = await getStays()
    })

    return {
      selected,
      stays,
    }
  }
}
</script>
