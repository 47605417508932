<template>
  <form enctype="multipart/form-data" @submit.prevent="submit">
    <Errors v-if="errors.length > 0" title="Oops, looks like you need to fix something:" :errors="errors" class="mb-4" />

    <dl class="divide-y divide-gray-200">
      <div class="amo-details">
        <dt><label for="date">Date</label></dt>
        <dd>
          <input v-model="expense.date" type="date" id="date" class="amo-input w-48" />
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="name">Payee</label></dt>
        <dd>
          <input v-model="expense.name" type="text" id="name" class="amo-input w-48" />
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="category">Category</label></dt>
        <dd>
          <CategorySelect v-model="expense.category_id" id="category" class="ml-6 w-full" />
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="stay">Stay</label></dt>
        <dd>
          <StaySelect v-model="expense.stay_id" id="stay" class="ml-6 w-full" />
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="amount">Amount</label></dt>
        <dd>
          <div class="relative w-48">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <CurrencyInput v-model="expense.amount" class="amo-input pl-7 pr-12 text-right" id="amount" aria-describedby="price-currency" />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">USD</span>
            </div>
          </div>
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="cleared">Cleared</label></dt>
        <dd>
          <Switch v-model="expense.cleared" id="cleared" :class="[expense.cleared ? 'bg-green-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500']">
            <span aria-hidden="true" :class="[expense.cleared ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="shared">Shared</label></dt>
        <dd>
          <Switch v-model="expense.shared" id="shared" :class="[expense.shared ? 'bg-green-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500']">
            <span aria-hidden="true" :class="[expense.shared ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="memo">Memo</label></dt>
        <dd>
          <textarea rows="2" v-model="expense.memo" id="memo" class="amo-input w-48" />
        </dd>
      </div>

      <div class="amo-details-vertical">
        <dt>Attachment</dt>
        <dd>
          <AttachmentInput v-model="expense.attachment" />
        </dd>
      </div>
    </dl>

    <div class="py-5">
      <div class="flex justify-end">
        <button @click="close" type="button" class="amo-btn-secondary">
          Cancel
        </button>
        <button type="submit" class="ml-3 inline-flex justify-center amo-btn">
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

import Errors from '@/components/Errors'
import StaySelect from '@/components/StaySelect'
import CategorySelect from '@/components/CategorySelect'
import CurrencyInput from '@/components/CurrencyInput'
import AttachmentInput from '@/components/AttachmentInput'
import { createExpense } from '@/api'
import mixpanel from '@/utils/mixpanel'

export default {
  components: {
    Errors,
    CurrencyInput,
    AttachmentInput,
    StaySelect,
    CategorySelect,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const expense = reactive({
      date: dayjs().format('YYYY-MM-DD'),
      name: '',
      category_id: null,
      amount: null,
      stay_id: null,
      cleared: false,
      shared: false,
      memo: '',
      attachment: null,
    })

    const errors = ref([])

    return {
      expense,
      errors,
      close: () => router.push({ name: 'expenses' }),
      submit: () => {
        const data = new FormData()

        data.append("date", expense.date)
        data.append("name", expense.name)
        data.append("category_id", expense.category_id)
        data.append("amount", expense.amount)
        data.append("cleared", expense.cleared)
        data.append("shared", expense.shared)
        data.append("memo", expense.memo)

        if (expense.stay_id) {
          data.append("stay_id", expense.stay_id)
        }

        if (expense.attachment) {
          data.append("attachment", expense.attachment)
        }

        createExpense(data)
          .then(() => {
            mixpanel.track('Add Expense', { type: 'transaction' })
            store.dispatch('expenses/getAll')
            router.push({ name: 'expenses' })
          })
          .catch(error => {
            const { response } = error

            if (response) {
              errors.value = response.data.errors
            }
          })
      }
    }
  }
}
</script>
