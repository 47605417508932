<template>
  <div class="flex items-center justify-between">
    <div class="flex-1 min-w-0">
      <h1 class="amo-h1">
        Stays
      </h1>
    </div>
    <div class="flex ml-4">
      <router-link :to="{ name: 'addStay' }" custom v-slot="{ navigate }">
        <button @click="navigate" class="inline-flex items-center amo-btn">
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Add Stay
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from '@heroicons/vue/outline'

export default {
  components: {
    PlusIcon
  },
}
</script>
