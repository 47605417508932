<template>
  <div class="my-10 flex justify-end">
    <DateRangeInput />
  </div>

  <Overview :stays="stays" :loaded="loaded" />

  <StaysList :stays="stays" v-if="stays.length > 0" />

  <EmptyState
    v-if="loaded && stays.length === 0"
    title="No Stays"
    description="Get started by adding stays, including rentals, personal use, and maintenance."
    :to="{ name: 'addStay' }"
    label="Add Stay"
  />

  <Panel @close="closePanel"><router-view></router-view></Panel>
</template>

<script>
import { toRefs, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'

import DateRangeInput from '@/components/DateRangeInput'
import Overview from './Overview'
import StaysList from './StaysList'
import EmptyState from '@/components/EmptyState'
import Panel from '@/components/Panel'
import { useDatesSubscriber } from '@/composables/dates'

export default {
  components: {
    DateRangeInput,
    Overview,
    StaysList,
    EmptyState,
    Panel,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    useDatesSubscriber(store => {
      store.dispatch('stays/getAll')
    })

    onMounted(() => store.dispatch('stays/getAll'))

    const stays = computed(() => store.state.stays.all)
    const loaded = computed(() => store.state.stays.loaded)

    return {
      stays,
      loaded,
      closePanel: () => router.push({ name: 'stays' }),
    }
  }
}
</script>
