import { createApp } from 'vue'
import Rollbar from 'rollbar'
import router from '@/router'
import store from '@/store'

const app = createApp({
  template: '<router-view></router-view>',
})

app.config.globalProperties.$rollbar = new Rollbar({
  enabled: process.env.NODE_ENV === 'production',
  accessToken: '2d2d0993037b4903ae9e24c798e29302',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV,
  },
})

app.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err)
  throw err
}

app.use(router)
app.use(store)

export default app
