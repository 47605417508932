<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" src="../../../images/logo.svg" alt="amodomo" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Don't have an account yet?
          <router-link :to="{ name: 'sign_up' }" class="amo-link font-medium">
            Sign Up
          </router-link>
        </p>
      </div>

      <Alert v-if="errors.length > 0" :message="errors.join(' ')" />

      <form class="mt-8 space-y-6" @submit.prevent="submit">
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email" class="sr-only">Email address</label>
            <input id="email" type="email" v-model="params.email" autocomplete="email" required class="amo-input rounded-none rounded-t-md focus:z-10" placeholder="Email address">
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input id="password" type="password" v-model="params.password" autocomplete="current-password" required class="amo-input rounded-none rounded-b-md focus:z-10" placeholder="Password">
          </div>
        </div>

        <div>
          <button type="submit" class="group relative w-full amo-btn">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-green-300 group-hover:text-green-400" aria-hidden="true" />
            </span>
            Sign in
          </button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { LockClosedIcon } from '@heroicons/vue/solid'
import Alert from '@/components/Alert'
import { authenticate } from '@/api'
import mixpanel from '@/utils/mixpanel'

export default {
  components: {
    Alert,
    LockClosedIcon,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const params = reactive({
      email: '',
      password: '',
    })

    const errors = ref([])

    return {
      params,
      errors,
      submit: () => {
        authenticate(params.email, params.password)
          .then(data => {
            store.dispatch('auth/login', data.token)
              .then(() => {
                mixpanel.track('Sign In')
                router.push({ name: 'stays' })
              })
          })
          .catch(error => {
            const { response } = error

            if (response) {
              errors.value = response.data.errors
            }
          })
      }
    }
  }
}
</script>
