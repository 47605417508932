<template>
  <div class="my-10 flex justify-end">
    <DateRangeInput />
  </div>

  <Overview :incomes="incomes" :loaded="loaded" />

  <IncomeList :incomes="incomes" v-if="incomes.length > 0" />

  <EmptyState
    v-if="loaded && incomes.length === 0"
    title="No Income"
    description="Get started by adding income to track your revenue and build reports"
    :to="{ name: 'addIncome' }"
    label="Add Income"
  />


  <Panel @close="closePanel"><router-view></router-view></Panel>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import DateRangeInput from '@/components/DateRangeInput'
import Panel from '@/components/Panel'
import Overview from './Overview'
import IncomeList from './IncomeList'
import EmptyState from '@/components/EmptyState'
import { useDatesSubscriber } from '@/composables/dates'

export default {
  components: {
    DateRangeInput,
    Overview,
    IncomeList,
    EmptyState,
    Panel,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    useDatesSubscriber(store => {
      store.dispatch('incomes/getAll')
    })

    const incomes = computed(() => store.state.incomes.all)
    const loaded = computed(() => store.state.incomes.loaded)

    onMounted(() => store.dispatch('incomes/getAll'))

    return {
      incomes,
      loaded,
      closePanel: () => router.push({ name: 'income' }),
    }
  }
}
</script>
