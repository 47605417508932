<template>
  <Overview v-if="loaded">
    <OverviewItem title="Income">
      <Currency :value="total" class="text-green-600" />
    </OverviewItem>
    <OverviewItem title="Paid Out">
      <Currency :value="cleared" class="text-green-600" />
    </OverviewItem>
    <OverviewItem title="Expected">
      <Currency :value="uncleared" class="text-green-600 opacity-50" />
    </OverviewItem>
  </Overview>
  <Overview v-else>
    <OverviewPlaceholder />
    <OverviewPlaceholder />
    <OverviewPlaceholder />
  </Overview>
</template>

<script>
import { computed, toRefs } from 'vue'
import Currency from '@/components/Currency'
import { Overview, OverviewItem, OverviewPlaceholder } from '@/components/Overview'

export default {
  components: {
    Currency,
    Overview,
    OverviewItem,
    OverviewPlaceholder,
  },
  props: ['incomes', 'loaded'],
  setup(props) {
    const { incomes } = toRefs(props)

    const sumOf = (attr) => {
      return (accum, stay) => accum + parseFloat(stay[attr])
    }

    const total = computed(() => incomes.value.reduce(sumOf('amount'), 0.0))
    const cleared = computed(() => incomes.value.filter(income => income.cleared).reduce(sumOf('amount'), 0.0))
    const uncleared = computed(() => incomes.value.filter(income => !income.cleared).reduce(sumOf('amount'), 0.0))

    return {
      total,
      cleared,
      uncleared,
    }
  }
}
</script>
