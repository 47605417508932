<template>
  <template v-if="!editing">
    <div class="value flex-grow w-full text-right" :class="type == 'textarea' ?  ['italic', 'text-gray-500'] : ['text-gray-700']">{{ formattedValue }}</div>
    <button type="button" title="Edit" @click="startEditing" class="ml-3 h-8 w-8 flex items-center justify-center text-gray-500 hover:text-gray-700 focus:outline-none focus:text-green-500">
      <PencilIcon class="h-5 w-5" aria-hidden="true" />
      <span class="sr-only">Edit</span>
    </button>
  </template>
  <template v-else>
    <template v-if="type == 'currency'">
      <div class="relative w-48">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <CurrencyInput v-model="editedValue" ref="input" @keydown.enter.stop="submit" @keydown.esc.stop="stopEditing" class="amo-input pl-7 pr-12 text-right" aria-describedby="price-currency" />
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm" id="price-currency">USD</span>
        </div>
      </div>
    </template>
    <textarea v-else-if="type == 'textarea'" v-model="editedValue" ref="input"
      @keydown.enter.stop="submit" @keydown.esc.stop="stopEditing" class="amo-textarea w-48 h-16 text-right" />
    <input v-else :type="type" v-model="editedValue" ref="input" @keydown.enter.stop="submit" @keydown.esc.stop="stopEditing" class="amo-input text-right w-48" />
    <button type="button" @click="stopEditing" class="ml-3 h-8 w-8 flex items-center justify-center text-gray-500 hover:text-gray-700 focus:outline-none focus:text-green-500">
      <XIcon class="h-5 w-5" aria-hidden="true" />
      <span class="sr-only">Cancel</span>
    </button>
  </template>
</template>

<script>
import { toRefs, ref, computed, nextTick } from 'vue'
import { PencilIcon, XIcon } from '@heroicons/vue/outline'
import CurrencyInput from '@/components/CurrencyInput'

export default {
  components: {
    CurrencyInput,
    PencilIcon,
    XIcon,
  },
  props: {
    modelValue: {
      required: true,
    },
    type: {
      required: false,
      default: "text",
    },
    formatter: {
      required: false,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, type, formatter } = toRefs(props)
    const editing = ref(false)
    const input = ref(null)
    const editedValue = ref(modelValue.value)

    const startEditing = async () => {
      let el = null

      editing.value = true
      await nextTick()
      input.value.value = modelValue.value // LOL

      if (type.value == 'currency') {
        el = input.value.$el
      } else {
        el = input.value
      }

      el.focus()
      el.select()
    }

    const stopEditing = () => {
      editing.value = false
    }

    const submit = () => {
      emit('update:modelValue', editedValue.value)
      editing.value = false
    }

    const formattedValue = computed(() => {
      if (formatter.value) {
        return formatter.value(modelValue.value)
      } else {
        return modelValue.value
      }
    })

    return {
      formattedValue,
      input,
      editedValue,
      editing,
      startEditing,
      stopEditing,
      submit,
    }
  },
}
</script>

<style scoped>
.value {
  @apply py-2 px-3;
  border: 1px solid transparent;
}
</style>
