<template>
	<Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
		<div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
			<div class="border-b border-gray-700">
				<div class="flex items-center justify-between h-16 px-4 sm:px-0">
					<div class="flex items-center">
						<div class="flex-shrink-0">
							<img class="block lg:hidden h-8 w-auto" src="../../images/logo.svg" alt="amodomo" />
							<img class="hidden lg:block h-8 w-auto" src="../../images/logo-name.svg" alt="amodomo" />
						</div>
						<div class="hidden md:block">
							<div class="ml-10 flex items-baseline space-x-4">
                <router-link :to="{ name: 'stays' }" class="amo-nav-link" active-class="amo-nav-link-active">Stays</router-link>
                <router-link :to="{ name: 'income' }" class="amo-nav-link" active-class="amo-nav-link-active">Income</router-link>
                <router-link :to="{ name: 'expenses' }" class="amo-nav-link" active-class="amo-nav-link-active">Expenses</router-link>
                <router-link :to="{ name: 'reports' }" class="amo-nav-link" active-class="amo-nav-link-active">Reports</router-link>
							</div>
						</div>
					</div>
					<div class="hidden md:block">
						<div class="ml-4 flex items-center md:ml-6">
							<!-- Profile dropdown -->
							<Menu v-if="account" as="div" class="ml-3 relative">
								<div>
									<MenuButton class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
										<span class="sr-only">Open user menu</span>
										<img class="h-8 w-8 rounded-full" :src="account.avatar_url" alt="" />
									</MenuButton>
								</div>
								<transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
									<MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div class="py-4 px-4">
                      <p class="text-sm font-medium text-gray-900 truncate">{{ account.email }}</p>
                    </div>
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <button @click="logout" :class="[active ? 'bg-gray-100' : '', 'w-full group flex items-center px-4 py-2 text-sm text-gray-700']">
                          <LogoutIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                          Sign out
                        </button>
                      </MenuItem>
                    </div>
									</MenuItems>
								</transition>
							</Menu>
						</div>
					</div>
					<div class="-mr-2 flex md:hidden">
						<!-- Mobile menu button -->
						<DisclosureButton class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
							<span class="sr-only">Open main menu</span>
							<MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
							<XIcon v-else class="block h-6 w-6" aria-hidden="true" />
						</DisclosureButton>
					</div>
				</div>
			</div>
		</div>

		<DisclosurePanel class="border-b border-gray-700 md:hidden">
			<div class="px-2 py-3 space-y-1 sm:px-3">
        <router-link :to="{ name: 'stays' }" class="block amo-nav-link" active-class="amo-nav-link-active"><DisclosureButton>Stays</DisclosureButton></router-link>
        <router-link :to="{ name: 'income' }" class="block amo-nav-link" active-class="amo-nav-link-active"><DisclosureButton>Income</DisclosureButton></router-link>
        <router-link :to="{ name: 'expenses' }" class="block amo-nav-link" active-class="amo-nav-link-active"><DisclosureButton>Expenses</DisclosureButton></router-link>
        <router-link :to="{ name: 'reports' }" class="block amo-nav-link" active-class="amo-nav-link-active"><DisclosureButton>Reports</DisclosureButton></router-link>
			</div>
			<div class="pt-4 pb-3 border-t border-gray-700">
				<div class="flex items-center px-5">
					<div class="flex-shrink-0">
						<img class="h-10 w-10 rounded-full" :src="account.avatar_url" alt="" />
					</div>
					<div class="ml-3">
            <div class="text-base font-medium leading-none text-white">{{ account.email }}</div>
					</div>
				</div>
				<div class="mt-3 px-2 space-y-1">
					<button @click="logout" class="w-full group flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">
						<LogoutIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-white group-hover:bg-gray-700" aria-hidden="true" />
						Sign out
					</button>
				</div>
			</div>
		</DisclosurePanel>
	</Disclosure>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { LogoutIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'
import mixpanel from '@/utils/mixpanel'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    LogoutIcon,
    MenuIcon,
    XIcon,
  },
  props: ['account'],
  setup() {
    const store = useStore()
    const router = useRouter()
    const open = ref(false)
    const account = computed(() => store.state.account.user)

    const logout = () => {
      store.dispatch('auth/logout').then(() => {
        mixpanel.track('Sign Out')
        router.push({ name: 'sign_in' })
      })
    }

    return {
      open,
      account,
      logout,
    }
  },
}
</script>
