import dayjs from 'dayjs'
import quarterOfYearPlugin from 'dayjs/plugin/quarterOfYear'

dayjs.extend(quarterOfYearPlugin)

const startOf = (date, unit) => date.startOf(unit).format('YYYY-MM-DD')
const endOf = (date, unit) => date.endOf(unit).format('YYYY-MM-DD')

const presets = [
  {
    name: 'Current Month',
    start: () => startOf(dayjs(), 'month'),
    end: () => endOf(dayjs(), 'month'),
  },
  {
    name: 'Last Month',
    start: () => startOf(dayjs().subtract(1, 'month'), 'month'),
    end: () => endOf(dayjs().subtract(1, 'month'), 'month'),
  },
  {
    name: 'Current Quarter',
    start: () => startOf(dayjs(), 'quarter'),
    end: () => endOf(dayjs(), 'quarter'),
  },
  {
    name: 'Last Quarter',
    start: () => startOf(dayjs().subtract(1, 'quarter'), 'quarter'),
    end: () => endOf(dayjs().subtract(1, 'quarter'), 'quarter'),
  },
  {
    name: 'Current Year',
    start: () => startOf(dayjs(), 'year'),
    end: () => endOf(dayjs(), 'year'),
  },
  {
    name: 'Last Year',
    start: () => startOf(dayjs().subtract(1, 'year'), 'year'),
    end: () => endOf(dayjs().subtract(1, 'year'), 'year'),
  },
]

export default presets
