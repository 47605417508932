<template>
	<div class="px-4 sm:px-6">
    <h2 class="amo-h2 mb-6">Add Expense</h2>

    <TabGroup>
      <TabList class="tabs">
        <Tab v-slot="{ selected }" as="template">
          <button class="tab" :class="{ selected }">
            <CreditCardIcon class="icon" aria-hidden="true" />
            <span>Transaction</span>
          </button>
        </Tab>
        <Tab v-slot="{ selected }" as="template">
          <button class="tab" :class="{ selected }">
            <MapIcon class="icon" aria-hidden="true" />
            <span>Mileage</span>
          </button>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel tabindex="-1">
          <AddExpenseTransaction />
        </TabPanel>
        <TabPanel tabindex="-1">
          <AddExpenseMileage />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { MapIcon, CreditCardIcon } from '@heroicons/vue/outline'

import AddExpenseTransaction from './AddExpenseTransaction'
import AddExpenseMileage from './AddExpenseMileage'
import store from '@/store'

export default {
  components: {
    AddExpenseTransaction,
    AddExpenseMileage,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    MapIcon,
    CreditCardIcon,
  },
  beforeRouteEnter() {
    store.commit('panel/open')
  },
  beforeRouteLeave() {
    store.commit('panel/close')
  },
}
</script>

<style scoped>
  .tabs {
    @apply flex justify-items-stretch w-full border-b border-gray-200 mb-4;
  }

  .tab {
    @apply flex-grow group inline-flex items-center justify-center py-4 px-1 border-b-2 font-medium text-sm;
  }

  .tab.selected {
    @apply border-green-500 text-green-600;
  }

  .tab:not(.selected) {
    @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300;
  }

  .icon {
    @apply -ml-0.5 mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500;
  }

  .selected .icon {
    @apply text-green-600;
  }
</style>
