<template>
	<div class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 bg-white text-center">
    <h3 class="mt-2 text-xl font-medium text-gray-900">{{ title }}</h3>
    <p class="mt-2 text-gray-500">
      {{ description }}
    </p>
    <div class="mt-6">
      <router-link :to="to" custom v-slot="{ navigate }">
        <button @click="navigate" class="inline-flex items-center amo-btn">
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          {{ label }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { PlusIcon } from '@heroicons/vue/outline'

export default {
  components: {
    PlusIcon,
  },
  props: ['title', 'description', 'label', 'to'],
}
</script>
