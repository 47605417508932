<template>
  <input type="tel" :value="modelValue" @input="update" placeholder="0.00" />
</template>

<script>
function format(input) {
  input = input ? input.toString() : ''

  const prefix = input.indexOf('-') >= 0 ? '-' : ''

  let numbers = input.replace(/\D+/g, '') || '0'
  numbers = (parseFloat(numbers) / 100).toFixed(2)

  return prefix + numbers.toString()
}

export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    return {
      update: (event) => {
        let fmt = format(event.target.value)

        if (fmt === "0.00") {
          fmt = null
        }

        emit('update:modelValue', fmt)
      }
    }
  }
}
</script>
