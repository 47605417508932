<template>
  <div class="flex flex-col w-full rounded-lg bg-white shadow items-center border border-gray-300 sm:flex-row md:w-auto justify-between">
    <PresetSelect :selected="preset" @select="updatePreset" class="rounded-t-lg border-b border-gray-300 sm:rounded-none sm:rounded-l-lg sm:border-b-0 sm:border-r text-center sm:text-left" />
    <div class="flex items-center w-full">
      <input type="date" :value="start" @change="updateDate('start', $event.target.value)" class="rounded-bl-lg sm:rounded-none" />
      <div>–</div>
      <input type="date" :value="end" @change="updateDate('end', $event.target.value)" class="rounded-br-lg sm:rounded-none sm:rounded-r-lg" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import PresetSelect from './PresetSelect'
import debounce from 'lodash/debounce'

export default {
  components: {
    PresetSelect,
  },
  setup() {
    const store = useStore()

    const updatePreset = name => store.commit('dates/setPreset', { name })

    const updateDate = debounce((attr, date) => {
      store.commit('dates/setCustom', { [attr]: date })
    }, 500)

    return {
      preset: computed(() => store.state.dates.preset),
      start: computed(() => store.state.dates.start),
      end: computed(() => store.state.dates.end),
      updatePreset,
      updateDate,
    }
  },
}
</script>

<style scoped>
  input[type=date] {
    @apply w-full md:w-36 border-0 focus:ring-green-400 focus:ring-2 focus:ring-inset text-sm text-center sm:text-right;
  }

  input[type=date]::-webkit-inner-spin-button,
  input[type=date]::-webkit-outer-spin-button,
  input[type=date]::-webkit-calendar-picker-indicator {
    @apply pl-2;
    margin: 0;
  }
</style>
