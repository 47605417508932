import api from '@/api'

const state = () => ({
  loaded: false,
  all: [],
})

const getters = {}

const actions = {
  getAll ({ commit, rootState }) {
    const { start, end } = rootState.dates

    api.getIncomes({ start, end }).then(incomes => {
      commit('setAll', incomes)
    })
  },
}

const mutations = {
  setAll(state, incomes) {
    state.loaded = true
    state.all = incomes
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
