<template>
  <div v-if="'usage' in report">
    <div class="line-item line-item-heading">
      <div class="line-item-name">Rental Use</div>
      <div class="line-item-value">{{ formatPercent(report.usage.ratio) }}</div>
    </div>
    <ul class="report-section">
      <li class="line-item line-item-group">
        <div class="line-item-name">Rental</div>
        <div class="line-item-value">{{ report.usage.rental }} days</div>
      </li>
      <li class="line-item line-item-group">
        <div class="line-item-name">Maintenance</div>
        <div class="line-item-value">{{ report.usage.maintenance }} days</div>
      </li>
      <li class="line-item line-item-group">
        <div class="line-item-name">Personal</div>
        <div class="line-item-value">{{ report.usage.personal }} days</div>
      </li>
    </ul>

    <div class="line-item line-item-heading">
      <div class="line-item-name">Expenses</div>
      <div class="line-item-value"><Currency :value="report.expenses.total" class="text-red-600" /></div>
    </div>
    <ul class="report-section">
			<Disclosure as="li" v-for="group in report.expenses.groups" :key="group.name" class="line-item-group flex flex-col" v-slot="{ open }">
				<DisclosureButton class="relative w-full flex justify-between items-center">
					<span class="absolute">
						<ChevronDownIcon class="text-gray-500 h-4 w-4 transform" :class="[open ? '-rotate-180' : 'rotate-0']" aria-hidden="true" />
					</span>
					<span class="line-item-name">{{ group.name }}</span>
					<span class="line-item-value"><Currency :value="group.total" class="text-red-600" /></span>
				</DisclosureButton>
				<DisclosurePanel as="dl" class="flex flex-col opacity-75 text-sm">
          <div class="flex justify-between">
            <dt class="line-item-name">Direct</dt>
            <dd class="line-item-value"><Currency :value="group.direct" class="text-red-600" /></dd>
          </div>

          <div class="flex justify-between">
            <dt class="line-item-name">Shared</dt>
            <dd class="line-item-value"><Currency :value="group.shared" class="text-red-600" /></dd>
          </div>

          <div class="flex justify-between">
            <dt class="line-item-name">Adjustment</dt>
            <dd class="line-item-value"><Currency :value="group.adjustment" class="text-red-600" /></dd>
          </div>
				</DisclosurePanel>
			</Disclosure>
    </ul>

    <div class="line-item line-item-heading">
      <div class="line-item-name">Income</div>
      <div class="line-item-value"><Currency :value="report.incomes.total" class="text-green-600" /></div>
    </div>
    <ul class="report-section">
      <li v-for="group in report.incomes.groups" class="line-item line-item-group">
        <div class="line-item-name">{{ group.name }}</div>
        <div class="line-item-value"><Currency :value="group.total" class="text-green-600" /></div>
      </li>
    </ul>

    <div v-if="'total' in report" class="report-section">
      <div class="line-item line-item-heading">
        <div class="line-item-name">Total Income or Loss</div>
        <div class="line-item-value"><Currency :value="report.total" colored /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'
import { incomeAndLossReport } from '@/api'
import Currency from '@/components/Currency'
import { formatPercent } from '@/utils/helpers'
import { useDatesSubscriber } from '@/composables/dates'

export default {
  components: {
    Currency,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },
  setup() {
    const store = useStore()
    const report = ref({})
    const start = computed(() => store.state.dates.start)
    const end = computed(() => store.state.dates.end)

    useDatesSubscriber(store => {
      getReport()
    })

    const getReport = async () => {
      report.value = await incomeAndLossReport({
        start: start.value,
        end: end.value,
      })
    }

    onMounted(getReport)

    return {
      report,
      formatPercent
    }
  }
}
</script>

<style scoped>
.report-section {
  @apply mb-5 text-base text-gray-700;
}

.report-section-heading {
  @apply text-lg leading-6 font-light uppercase text-base mb-2;
}

.line-item {
  @apply flex flex-row justify-between;
}

.line-item-heading {
  @apply text-lg font-bold mb-2;
}

.line-item-group {
  @apply border-b;
}

.line-item-group .line-item-name {
  @apply pl-5;
}

.line-item-name {
  @apply text-left pr-3 bg-white overflow-hidden overflow-ellipsis whitespace-nowrap flex-grow;
}

.line-item-value {
  @apply bg-white pl-4 font-mono;
}
</style>
