<template>
	<div class="px-4 sm:px-6">
		<div class="flex justify-between items-center pb-6">
      <h2 class="amo-h2">Expense Details</h2>
      <button type="button" title="Delete" @click="destroy" class="ml-2 bg-white rounded-full h-8 w-8 flex items-center justify-center text-red-600 hover:bg-gray-100 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:bg-gray-100">
        <TrashIcon class="h-5 w-5" aria-hidden="true" />
        <span class="sr-only">Delete</span>
      </button>
		</div>
		<dl class="divide-y divide-gray-200">
			<div class="amo-details">
				<dt>Date</dt>
				<dd>
          <EditableInput v-model="expense.date" type="date" :formatter="formatDate" />
        </dd>
			</div>

			<div class="amo-details">
				<dt>Payee</dt>
				<dd>
          <EditableInput v-model="expense.name" />
        </dd>
			</div>

      <div class="amo-details">
        <dt>Category</dt>
        <dd>
          <CategorySelect v-model="expense.category_id" id="category" class="ml-6 w-48 sm:w-full" />
        </dd>
      </div>

      <div class="amo-details">
        <dt>Stay</dt>
        <dd>
          <StaySelect v-model="expense.stay_id" id="stay" class="ml-6 w-48 sm:w-full" />
        </dd>
      </div>

			<div class="amo-details">
				<dt>Amount</dt>
				<dd>
          <EditableInput v-model="expense.amount" type="currency" :formatter="formatCurrency" />
        </dd>
			</div>

			<div class="amo-details">
				<dt>Cleared</dt>
				<dd>
          <Switch v-model="expense.cleared" :class="[expense.cleared ? 'bg-green-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500']">
            <span aria-hidden="true" :class="[expense.cleared ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
        </dd>
			</div>

			<div class="amo-details">
				<dt>Shared</dt>
				<dd>
          <Switch v-model="expense.shared" :class="[expense.shared ? 'bg-green-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500']">
            <span aria-hidden="true" :class="[expense.shared ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
        </dd>
			</div>

			<div class="amo-details">
				<dt>Memo</dt>
        <dd>
          <EditableInput v-model="expense.memo" type="textarea" />
        </dd>
			</div>

      <div class="amo-details-vertical">
        <dt>Attachment</dt>
        <dd>
          <AttachmentInput v-model="expense.attachment" />
        </dd>
      </div>
		</dl>
	</div>
</template>

<script>
import { ref, toRefs, watch } from 'vue'
import { useRouter } from 'vue-router'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { PaperClipIcon, PencilIcon, TrashIcon } from '@heroicons/vue/outline'
import { Switch } from '@headlessui/vue'
import store from '@/store'
import EditableInput from '@/components/EditableInput'
import StaySelect from '@/components/StaySelect'
import CategorySelect from '@/components/CategorySelect'
import AttachmentInput from '@/components/AttachmentInput'
import { formatCurrency, formatDate } from '@/utils/helpers'
import { diff } from '@/utils/diff'
import { updateExpense, destroyExpense, removeExpenseAttachment } from '@/api'
import mixpanel from '@/utils/mixpanel'

export default {
	components: {
    AttachmentInput,
    EditableInput,
    StaySelect,
    CategorySelect,
    Switch,
		PaperClipIcon,
    PencilIcon,
		TrashIcon,
	},
  props: ['expense'],
  emits: ['refresh'],
  setup(props, { emit }) {
    const { expense } = toRefs(props)
    const router = useRouter()

    const update = async (params) => {
      const data = new FormData()
      Object.keys(params).forEach(key => data.append(key, params[key] || ''))
      await updateExpense(expense.value.id, data)
      emit('refresh')
      mixpanel.track('Edit Expense', {
        ...params,
        type: 'transaction',
      })
      store.dispatch('expenses/getAll')
    }

    const detach = async () => {
      await removeExpenseAttachment(expense.value.id)
      emit('refresh')
      store.dispatch('expenses/getAll')
    }

    const destroy = async () => {
      await destroyExpense(expense.value.id)
      mixpanel.track('Delete Expense', { type: 'transaction' })
      store.dispatch('expenses/getAll')
      router.push({ name: 'expenses' })
    }

    watch(
      () => cloneDeep(expense.value),
      (current, previous) => {
        const params = diff(previous, current)

        delete params['category'] // Hackety, hack, hack

        // Don't do anything if expense is loading for the first time
        if (isEmpty(previous)) {
          return
        }

        // Special treatment of attachments
        //   - If null, explicitly remove the attachment
        //   - If File, allow the attachment to be updated normally
        //   - If string (else), it means the attachment was likely recently
        //     updated and nothing new should happen
        if (params.hasOwnProperty('attachment')) {
          if (!params.attachment) {
            detach()
          }

          // If we're not updating the attachment, get rid of it!
          if (!(params.attachment instanceof File)) {
            delete params['attachment']
          }
        }

        if (!isEmpty(params)) {
          update(params)
        }
      }
    )

    return {
      expense,
      destroy,
      close: () => router.push({ name: 'expenses' }),
      formatCurrency,
      formatDate,
    }
  }
}
</script>
