<template>
	<div class="px-4 sm:px-6">
    <form enctype="multipart/form-data" @submit.prevent="submit">
      <h2 class="amo-h2 mb-6">Add Income</h2>

      <Errors v-if="errors.length > 0" title="Oops, looks like you need to fix something:" :errors="errors" class="mb-6" />

      <dl class="divide-y divide-gray-200">
        <div class="amo-details">
          <dt><label for="date">Date</label></dt>
          <dd>
            <input v-model="income.date" type="date" id="date" class="amo-input w-48" />
          </dd>
        </div>

        <div class="amo-details">
          <dt><label for="name">Payer</label></dt>
          <dd>
            <input v-model="income.name" type="text" id="name" class="amo-input w-48" />
          </dd>
        </div>

        <div class="amo-details">
          <dt><label for="amount">Amount</label></dt>
          <dd>
            <div class="relative w-48">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm">$</span>
              </div>
              <CurrencyInput v-model="income.amount" class="amo-input pl-7 pr-12 text-right" id="amount" aria-describedby="price-currency" />
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-500 sm:text-sm" id="price-currency">USD</span>
              </div>
            </div>
          </dd>
        </div>

        <div class="amo-details">
          <dt><label for="cleared">Cleared</label></dt>
          <dd>
            <Switch v-model="income.cleared" id="cleared" :class="[income.cleared ? 'bg-green-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500']">
              <span aria-hidden="true" :class="[income.cleared ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
            </Switch>
          </dd>
        </div>

        <div class="amo-details">
          <dt><label for="memo">Memo</label></dt>
          <dd>
            <textarea rows="2" v-model="income.memo" id="memo" class="amo-input w-48" />
          </dd>
        </div>

        <div class="amo-details">
          <dt><label for="stay">Stay</label></dt>
          <dd>
            <StaySelect v-model="income.stay_id" id="stay" class="ml-6 w-full" />
          </dd>
        </div>

        <div class="amo-details-vertical">
          <dt>Attachment</dt>
          <dd>
            <AttachmentInput v-model="income.attachment" />
          </dd>
        </div>
      </dl>

      <div class="py-5">
        <div class="flex justify-end">
          <button @click="close" type="button" class="amo-btn-secondary">
            Cancel
          </button>
          <button type="submit" class="ml-3 inline-flex justify-center amo-btn">
            Save
          </button>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

import Errors from '@/components/Errors'
import StaySelect from '@/components/StaySelect'
import CurrencyInput from '@/components/CurrencyInput'
import AttachmentInput from '@/components/AttachmentInput'
import { createIncome } from '@/api'
import store from '@/store'
import mixpanel from '@/utils/mixpanel'

export default {
  components: {
    Errors,
    CurrencyInput,
    AttachmentInput,
    StaySelect,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  beforeRouteEnter() {
    store.commit('panel/open')
  },
  beforeRouteLeave() {
    store.commit('panel/close')
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const income = reactive({
      date: dayjs().format('YYYY-MM-DD'),
      name: '',
      stay_id: null,
      amount: null,
      cleared: false,
      memo: '',
      attachment: null,
    })

    const errors = ref([])

    return {
      income,
      errors,
      close: () => router.push({ name: 'income' }),
      submit: () => {
        const data = new FormData()

        data.append("date", income.date)
        data.append("name", income.name)
        data.append("amount", income.amount)
        data.append("cleared", income.cleared)
        data.append("memo", income.memo)

        if (income.stay_id) {
          data.append("stay_id", income.stay_id)
        }

        if (income.attachment) {
          data.append("attachment", income.attachment)
        }

        createIncome(data)
          .then(() => {
            mixpanel.track('Add Income')
            store.dispatch('incomes/getAll')
            router.push({ name: 'income' })
          })
          .catch(error => {
            const { response } = error

            if (response) {
              errors.value = response.data.errors
            }
          })
      }
    }
  }
}
</script>
