<template>
  <div class="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md mb-8">
      <img class="mx-auto h-12 w-auto" src="../../../images/logo.svg" alt="amodomo" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Try Amodomo<br>
				free for 30 days
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Already have an account?
        <router-link :to="{ name: 'sign_in' }" class="amo-link font-medium">
          Sign In
        </router-link>
      </p>
    </div>

    <Errors v-if="errors.length > 0" title="Oops, looks like you need to fix something:" :errors="errors" class="sm:mb-8 sm:mx-auto sm:w-full sm:max-w-md" />

    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="submit">
          <div>
            <label for="email" class="amo-label">
              Email address
            </label>
            <div class="mt-1 validated-input-group">
              <input id="email" v-model="account.email" type="email"  v-focus autocomplete="email" class="amo-input" />
              <div class="icon" v-if="validations.email.dirty">
                <XCircleIcon v-if="!validations.email.valid" class="h-5 w-5 text-red-500" aria-hidden="true" />
                <CheckCircleIcon v-if="validations.email.valid" class="h-5 w-5 text-green-500" aria-hidden="true" />
              </div>
            </div>
          </div>

          <div>
            <label for="password" class="amo-label">
              Password
            </label>
            <div class="mt-1 validated-input-group">
              <input
                type="password"
                id="password"
                v-model="account.password"
                class="amo-input"
                @focus="showPasswordDetails = true"
              />
              <div class="icon" v-if="validations.password.dirty">
                <XCircleIcon v-if="!validations.password.valid" class="h-5 w-5 text-red-500" aria-hidden="true" />
                <CheckCircleIcon v-if="validations.password.valid" class="h-5 w-5 text-green-500" aria-hidden="true" />
              </div>
            </div>
            <div class="overflow-hidden">
              <TransitionRoot
                :show="showPasswordDetails"
                enter="transition ease-in-out duration-1000 transform"
                enter-from="opacity-0 -translate-y-full"
                enter-to="opacity-100 translate-y-0"
              >
                <ul class="mt-2 ml-2 text-sm text-gray-500">
                  <li v-for="detail in passwordDetails" class="flex items-center" :class="[detail.valid ? 'text-green-600' : '']">
                    <CheckCircleIcon v-if="detail.valid" class="h-4 w-4" />
                    <XCircleIcon v-else class="h-4 w-4" />
                    <span class="ml-2">{{ detail.text }}</span>
                  </li>
                </ul>
              </TransitionRoot>
            </div>
          </div>

          <div>
            <label for="password_confirmation" class="amo-label">
              Password Confirmation
            </label>
            <div class="mt-1 validated-input-group">
              <input id="password_confirmation" v-model="account.passwordConfirmation" type="password" class="amo-input" />
              <div class="icon" v-if="validations.passwordConfirmation.dirty">
                <XCircleIcon v-if="!validations.passwordConfirmation.valid" class="h-5 w-5 text-red-500" aria-hidden="true" />
                <CheckCircleIcon v-if="validations.passwordConfirmation.valid" class="h-5 w-5 text-green-500" aria-hidden="true" />
              </div>
            </div>
          </div>

          <div class="flex justify-center">
            <Captcha
              site-key="6Ldsvy0dAAAAAO2wPDd4P02tEUkOJRZF05jPA3e1"
              @complete="onCaptchaComplete"
              @expired="onCaptchaExpired"
            />
          </div>

          <div>
            <button type="submit" :disabled="submitDisabled" class="w-full amo-btn">
              Create Account
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { TransitionRoot } from '@headlessui/vue'
import { XCircleIcon } from '@heroicons/vue/outline'
import { CheckCircleIcon } from '@heroicons/vue/solid'
import Errors from '@/components/Errors'
import Captcha from '@/components/Captcha'
import { isEmail, minLength, hasUpper, hasLower, hasNumber, hasSymbol, matches } from '@/utils/validations'
import focus from '@/directives/focus'
import { createAccount } from '@/api'
import mixpanel from '@/utils/mixpanel'

export default {
  components: {
    TransitionRoot,
    Errors,
    Captcha,
    XCircleIcon,
    CheckCircleIcon,
  },
  directives: {
    focus,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const errors = ref([])
    const showPasswordDetails = ref(false)

    const account = reactive({
      email: '',
      password: '',
      passwordConfirmation: '',
      captcha: null,
    })

    const passwordDetails = computed(() => {
      const { password } = account

      return [
        {
          text: "Use 8 or more characters",
          valid: minLength(password, 8),
        },
        {
          text: "Use upper and lower case letters",
          valid: hasUpper(password) && hasLower(password),
        },
        {
          text: "Use a number",
          valid: hasNumber(password),
        },
        {
          text: "Use a symbol (e.g. !@#$)",
          valid: hasSymbol(password),
        }
      ]
    })

    const validations = computed(() => {
      const { email, password, passwordConfirmation, captcha } = account

      return {
        email: {
          dirty: email.length > 0,
          valid: isEmail(email),
        },
        password: {
          dirty: password.length > 0,
          valid: passwordDetails.value.every(detail => detail.valid),
        },
        passwordConfirmation: {
          dirty: passwordConfirmation.length > 0,
          valid: matches(password, passwordConfirmation),
        },
        captcha: {
          valid: captcha != null,
        },
      }
    })

    const submitDisabled = computed(() => {
      return Object.values(validations.value).some(v => !v.valid)
    })

    const onCaptchaComplete = token => {
      account.captcha = token
    }

    const onCaptchaExpired = () => {
      account.captcha = null
    }

    const submit = () => {
      createAccount({
        email: account.email,
        password: account.password,
        captcha: account.captcha,
      }).then(data => {
        store.dispatch('auth/login', data.token)
          .then(() => {
            mixpanel.track('Sign Up')
            router.push({ name: 'stays' })
          })
      }).catch(error => {
        const { response } = error

        if (response) {
          errors.value = response.data.errors
        } else {
          throw error
        }
      })
    }

    return {
      showPasswordDetails,
      passwordDetails,
      errors,
      validations,
      account,
      submit,
      submitDisabled,
      onCaptchaComplete,
      onCaptchaExpired,
    }
  }
}
</script>

<style scoped>
  .validated-input-group {
    @apply relative;
  }

  .validated-input-group input {
    @apply pr-10;
  }

  .validated-input-group .icon {
    @apply absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none;
  }
</style>
