const emailRegex = /^\S+@\S+\.\S+$/
const hasUpperRegex = /[A-Z]/
const hasLowerRegex = /[a-z]/
const hasNumberRegex = /[0-9]/
const hasSymbolRegex = /[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]/

export const isEmail = str => emailRegex.test(str)
export const minLength = (str, length) => str.length >= length
export const hasUpper = str => hasUpperRegex.test(str)
export const hasLower = str => hasLowerRegex.test(str)
export const hasNumber = str => hasNumberRegex.test(str)
export const hasSymbol = str => hasSymbolRegex.test(str)
export const matches = (str1, str2) => str1 === str2
