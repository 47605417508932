const authTokenKey = 'auth_token'

const state = () => ({
  token: localStorage.getItem(authTokenKey),
})

const getters = {
  isAuthenticated(state) {
    return state.token !== null
  }
}

const actions = {
  login({ commit }, token) {
    return new Promise((resolve, reject) => {
      localStorage.setItem(authTokenKey, token)
      commit('setToken', token)
      resolve()
    })
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(authTokenKey)
      commit('clearToken')
      resolve()
    })
  }
}

const mutations = {
  setToken(state, token) {
    state.token = token
  },
  clearToken(state) {
    state.token = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
