import Stays from '@/views/Stays/Main'
import StaysHeader from '@/views/Stays/Header'
import AddStay from '@/views/Stays/AddStay'
import ShowStay from '@/views/Stays/ShowStay'

export default {
  name: 'stays',
  path: '/stays',
  components: {
    default: Stays,
    Header: StaysHeader,
  },
  meta: {
    track: 'View Stays',
  },
  children: [
    {
      name: 'addStay',
      path: 'new',
      component: AddStay,
      meta: {
        track: null,
      },
    },
    {
      name: 'showStay',
      path: ':stayId(\\d+)',
      component: ShowStay,
      props: true,
      meta: {
        track: 'View Stay Details',
      },
    },
  ],
}
