import Expenses from '@/views/Expenses/Main'
import ExpensesHeader from '@/views/Expenses/Header'
import AddExpense from '@/views/Expenses/AddExpense'
import ShowExpense from '@/views/Expenses/ShowExpense'

export default {
  name: 'expenses',
    path: '/expenses',
    components: {
      default: Expenses,
        Header: ExpensesHeader,
    },
    meta: {
      track: 'View Expenses',
    },
    children: [
      {
        name: 'addExpense',
        path: 'new',
        component: AddExpense,
        meta: {
          track: null,
        },
      },
      {
        name: 'showExpense',
        path: ':expenseId(\\d+)',
        component: ShowExpense,
        props: true,
        meta: {
          track: 'View Expense Details',
        },
      },
    ],
}
