export class DateRange {
  constructor(start, end) {
    this.start = start
    this.end = end
  }

  *dates() {
    let current = this.start.clone()

    while (current <= this.end) {
      yield current
      current = current.add(1, 'day')
    }
  }

  *nights() {
    let current = this.start.clone()

    while (current < this.end) {
      yield current
      current = current.add(1, 'day')
    }
  }
}
