<template>
	<div v-if="stay" class="px-4 sm:px-6">
		<div class="flex justify-between items-center pb-6">
      <h2 class="amo-h2">Stay Details</h2>
      <button type="button" title="Delete" @click="destroy" class="ml-2 bg-white rounded-full h-8 w-8 flex items-center justify-center text-red-600 hover:bg-gray-100 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:bg-gray-100">
        <TrashIcon class="h-5 w-5" aria-hidden="true" />
        <span class="sr-only">Delete</span>
      </button>
		</div>
		<dl class="divide-y divide-gray-200">
			<div class="amo-details">
				<dt>Starts</dt>
				<dd>
          <EditableInput v-model="stay.starts_on" type="date" :formatter="formatDate" />
        </dd>
			</div>

			<div class="amo-details">
				<dt>Ends</dt>
				<dd>
          <EditableInput v-model="stay.ends_on" type="date" :formatter="formatDate" />
        </dd>
			</div>

			<div class="amo-details">
				<dt>Name</dt>
				<dd>
          <EditableInput v-model="stay.name" />
        </dd>
			</div>

      <div class="amo-details">
        <dt>Classification</dt>
        <dd>
          <select v-model="stay.classification" id="classification" name="classification" class="amo-input w-40 sm:w-48">
            <option value="personal">Personal</option>
            <option value="rental">Rental</option>
            <option value="maintenance">Maintenance</option>
          </select>
        </dd>
      </div>

			<div class="amo-details">
				<dt>Memo</dt>
        <dd>
          <EditableInput v-model="stay.memo" type="textarea" />
        </dd>
			</div>
		</dl>
	</div>
</template>

<script>
import { ref, toRefs, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { PencilIcon, TrashIcon } from '@heroicons/vue/outline'
import store from '@/store'
import EditableInput from '@/components/EditableInput'
import { formatDate } from '@/utils/helpers'
import { diff } from '@/utils/diff'
import { getStay, updateStay, destroyStay } from '@/api'
import mixpanel from '@/utils/mixpanel'

export default {
	components: {
    EditableInput,
    PencilIcon,
		TrashIcon,
	},
  beforeRouteEnter() {
    store.commit('panel/open')
  },
  beforeRouteLeave() {
    store.commit('panel/close')
  },
  props: ['stayId'],
  setup(props) {
    const { stayId } = toRefs(props)
    const stay = ref(null)
    const router = useRouter()

    const update = async (params) => {
      const data = new FormData()
      Object.keys(params).forEach(key => data.append(key, params[key] || ''))
      await updateStay(stayId.value, data)
      stay.value = await getStay(stayId.value)
      mixpanel.track('Edit Stay', params)
      store.dispatch('stays/getAll')
    }

    const destroy = async () => {
      await destroyStay(stayId.value)
      mixpanel.track('Delete Stay')
      store.dispatch('stays/getAll')
      router.push({ name: 'stays' })
    }

    onMounted(async () => {
      stay.value = await getStay(stayId.value)
    })

    watch(
      () => cloneDeep(stay.value),
      (current, previous) => {
        const params = diff(previous, current)

        if (isEmpty(previous) || isEmpty(params)) {
          return
        }

        update(params)
      }
    )

    return {
      stay,
      destroy,
      close: () => router.push({ name: 'stays' }),
      formatDate,
    }
  }
}
</script>
