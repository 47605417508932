import "stylesheets/application"
import "images/logo.svg"

import { mount } from 'vue'
import app from '@/App'

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('app')) {
    app.mount('#app')
  }
})
