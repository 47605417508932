import { onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'

export function useAccountSubscriber(handler) {
  const store = useStore()
  let unsubscribe = null

  onMounted(() => {
    unsubscribe = store.subscribe(mutation => {
      if (mutation.type === 'account/set') {
        handler(store.state.account)
      }
    })
  })

  onUnmounted(() => {
    if (unsubscribe) {
      unsubscribe()
    }
  })
}
