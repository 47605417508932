<template>
  <form enctype="multipart/form-data" @submit.prevent="submit">
    <Errors v-if="errors.length > 0" title="Oops, looks like you need to fix something:" :errors="errors" class="mb-4" />

    <dl class="divide-y divide-gray-200">
      <div class="amo-details">
        <dt><label for="date">Date</label></dt>
        <dd>
          <input v-model="expense.date" type="date" id="date" class="amo-input w-48" />
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="miles">Miles</label></dt>
        <dd>
          <input v-model="expense.miles" type="number" min="0" id="miles" class="amo-input w-28" />
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="rate">Mileage Rate</label></dt>
        <dd>
          <div class="relative w-48">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <CurrencyInput disabled :modelValue="rate" class="amo-input pl-7 pr-16 text-right" id="amount" aria-describedby="price-currency" />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">/ mile</span>
            </div>
          </div>
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="amount">Amount</label></dt>
        <dd>
          <div class="relative w-48">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm">$</span>
            </div>
            <CurrencyInput disabled :modelValue="amount" class="amo-input pl-7 pr-16 text-right" id="amount" aria-describedby="price-currency" />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-500 sm:text-sm" id="price-currency">USD</span>
            </div>
          </div>
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="stay">Stay</label></dt>
        <dd>
          <StaySelect v-model="expense.stay_id" id="stay" class="ml-6 w-full" />
        </dd>
      </div>

      <div class="amo-details">
        <dt><label for="memo">Memo</label></dt>
        <dd>
          <textarea rows="2" v-model="expense.memo" id="memo" class="amo-input w-48" />
        </dd>
      </div>

      <div class="amo-details-vertical">
        <dt>Attachment</dt>
        <dd>
          <AttachmentInput v-model="expense.attachment" />
        </dd>
      </div>
    </dl>

    <div class="py-5">
      <div class="flex justify-end">
        <button @click="close" type="button" class="amo-btn-secondary">
          Cancel
        </button>
        <button type="submit" class="ml-3 inline-flex justify-center amo-btn">
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'

import Errors from '@/components/Errors'
import StaySelect from '@/components/StaySelect'
import AttachmentInput from '@/components/AttachmentInput'
import CurrencyInput from '@/components/CurrencyInput'
import { createExpenseMileage } from '@/api'
import mixpanel from '@/utils/mixpanel'

export default {
  components: {
    Errors,
    StaySelect,
    AttachmentInput,
    CurrencyInput,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const expense = reactive({
      date: dayjs().format('YYYY-MM-DD'),
      miles: 0,
      stay_id: null,
      memo: '',
      attachment: null,
    })

    const rates = {
      default: 0.56,
      2022: 0.585,
      2021: 0.56,
      2020: 0.575,
      2019: 0.58,
      2018: 0.545,
      2017: 0.535,
      2016: 0.54,
      2015: 0.575,
      2014: 0.56,
      2013: 0.565,
      2012: 0.555,
    }

    const rate = computed(() => {
      const date = dayjs(expense.date, 'YYYY-MM-DD')

      if (date.isValid()) {
        const year = date.year()

        if (year in rates) {
          return rates[year]
        }
      }

      return rates.default
    })

    const amount = computed(() => {
      return (expense.miles * rate.value).toFixed(2)
    })

    const errors = ref([])

    return {
      expense,
      rate,
      amount,
      errors,
      close: () => router.push({ name: 'expenses' }),
      submit: () => {
        const data = new FormData()

        data.append("date", expense.date)
        data.append("miles", expense.miles)
        data.append("memo", expense.memo)

        if (expense.stay_id) {
          data.append("stay_id", expense.stay_id)
        }

        if (expense.attachment) {
          data.append("attachment", expense.attachment)
        }

        createExpenseMileage(data)
          .then(() => {
            mixpanel.track('Add Expense', { type: 'mileage' })
            store.dispatch('expenses/getAll')
            router.push({ name: 'expenses' })
          })
          .catch(error => {
            const { response } = error

            if (response) {
              errors.value = response.data.errors
            }
          })
      }
    }
  }
}
</script>
