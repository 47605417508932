import api from '@/api'

const state = () => ({
  user: null
})

const getters = {}

const actions = {
  get ({ commit }) {
    api.getAccount().then(account => {
      commit('set', account)
    })
  }
}

const mutations = {
  set(state, account) {
    state.user = account
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
