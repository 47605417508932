import Income from '@/views/Income/Main'
import IncomeHeader from '@/views/Income/Header'
import AddIncome from '@/views/Income/AddIncome'
import ShowIncome from '@/views/Income/ShowIncome'

export default {
  name: 'income',
  path: '/income',
  components: {
    default: Income,
    Header: IncomeHeader,
  },
  meta: {
    track: 'View Incomes',
  },
  children: [
    {
      name: 'addIncome',
      path: 'new',
      component: AddIncome,
      meta: {
        track: null,
      },
    },
    {
      name: 'showIncome',
      path: ':incomeId(\\d+)',
      component: ShowIncome,
      props: true,
      meta: {
        track: 'View Income Details',
      },
    },
  ],
}
