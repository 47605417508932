<template>
  <template v-if="expense">
    <component :is="expenseComponent" :expense="expense" @refresh="refresh" />
  </template>
</template>

<script>
import { ref, toRefs, computed, onMounted } from 'vue'
import ShowExpenseMileage from './ShowExpenseMileage'
import ShowExpenseTransaction from './ShowExpenseTransaction'
import { getExpense } from '@/api'
import store from '@/store'

export default {
  props: ['expenseId'],
  beforeRouteEnter() {
    store.commit('panel/open')
  },
  beforeRouteLeave() {
    store.commit('panel/close')
  },
  setup(props) {
    const { expenseId } = toRefs(props)
    const expense = ref(null)

    const expenseComponent = computed(() => {
      if (!expense.value) {
        return null
      }

      switch(expense.value.type) {
        case 'expense':
          return ShowExpenseTransaction
        case 'mileage':
          return ShowExpenseMileage
        default:
          return null
      }
    })

    onMounted(async () => {
      expense.value = await getExpense(expenseId.value)
    })

    return {
      expense,
      expenseComponent,
      refresh: async () => {
        expense.value = await getExpense(expenseId.value)
      },
    }
  }
}
</script>
