<template>
	<Listbox as="div" :model-value="selected" @update:modelValue="$emit('select', $event)" class="relative w-full sm:w-40">
		<ListboxButton class="relative pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 text-sm w-full sm:w-40" :class="$attrs.class">
			<span class="block truncate font-semibold">{{ name }}</span>
			<span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
				<SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
			</span>
		</ListboxButton>

		<transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
			<ListboxOptions class="w-full absolute z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
				<ListboxOption as="template" v-for="preset in presets" :key="preset.name" :value="preset.name" v-slot="{ active, selected }">
					<li :class="[active ? 'text-white bg-green-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
						<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
							{{ preset.name }}
						</span>

						<span v-if="selected" :class="[active ? 'text-white' : 'text-green-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
							<CheckIcon class="h-5 w-5" aria-hidden="true" />
						</span>
					</li>
				</ListboxOption>
			</ListboxOptions>
		</transition>
  </Listbox>
</template>

<script>
import { toRefs, computed } from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import presets from '@/utils/presets'

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  inheritAttrs: false,
  props: ['selected'],
  setup(props) {
    const { selected } = toRefs(props)

    return {
      name: computed(() => selected.value ? selected.value : 'Custom'),
      selected,
      presets,
    }
  },
}
</script>
