<template>
	<div v-if="income" class="px-4 sm:px-6">
		<div class="flex justify-between items-center pb-6">
      <h2 class="amo-h2">Income Details</h2>
      <button type="button" title="Delete" @click="destroy" class="ml-2 bg-white rounded-full h-8 w-8 flex items-center justify-center text-red-600 hover:bg-gray-100 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:bg-gray-100">
        <TrashIcon class="h-5 w-5" aria-hidden="true" />
        <span class="sr-only">Delete</span>
      </button>
		</div>
		<dl class="divide-y divide-gray-200">
			<div class="amo-details">
				<dt>Date</dt>
				<dd>
          <EditableInput v-model="income.date" type="date" :formatter="formatDate" />
        </dd>
			</div>

			<div class="amo-details">
				<dt>Payer</dt>
				<dd>
          <EditableInput v-model="income.name" />
        </dd>
			</div>

			<div class="amo-details">
				<dt>Amount</dt>
				<dd>
          <EditableInput v-model="income.amount" type="currency" :formatter="formatCurrency" />
        </dd>
			</div>

			<div class="amo-details">
				<dt>Cleared</dt>
				<dd>
          <Switch v-model="income.cleared" :class="[income.cleared ? 'bg-green-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500']">
            <span aria-hidden="true" :class="[income.cleared ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
        </dd>
			</div>

			<div class="amo-details">
				<dt>Memo</dt>
        <dd>
          <EditableInput v-model="income.memo" type="textarea" />
        </dd>
			</div>

			<div class="amo-details">
				<dt>Stay</dt>
				<dd>
          <StaySelect v-model="income.stay_id" class="ml-6 w-full" />
        </dd>
			</div>

      <div class="amo-details-vertical">
        <dt>Attachment</dt>
        <dd>
          <AttachmentInput v-model="income.attachment" />
        </dd>
      </div>
		</dl>
	</div>
</template>

<script>
import { ref, toRefs, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { PaperClipIcon, PencilIcon, TrashIcon } from '@heroicons/vue/outline'
import { Switch } from '@headlessui/vue'
import store from '@/store'
import EditableInput from '@/components/EditableInput'
import StaySelect from '@/components/StaySelect'
import AttachmentInput from '@/components/AttachmentInput'
import { formatCurrency, formatDate } from '@/utils/helpers'
import { diff } from '@/utils/diff'
import { getIncome, updateIncome, destroyIncome, removeIncomeAttachment } from '@/api'
import mixpanel from '@/utils/mixpanel'

export default {
	components: {
    AttachmentInput,
    EditableInput,
    StaySelect,
    Switch,
		PaperClipIcon,
    PencilIcon,
		TrashIcon,
	},
  beforeRouteEnter() {
    store.commit('panel/open')
  },
  beforeRouteLeave() {
    store.commit('panel/close')
  },
  props: ['incomeId'],
  setup(props) {
    const { incomeId } = toRefs(props)
    const income = ref(null)
    const router = useRouter()

    const update = async (params) => {
      const data = new FormData()
      Object.keys(params).forEach(key => data.append(key, params[key] || ''))
      await updateIncome(incomeId.value, data)
      income.value = await getIncome(incomeId.value)
      mixpanel.track('Edit Income', params)
      store.dispatch('incomes/getAll')
    }

    const detach = async () => {
      await removeIncomeAttachment(incomeId.value)
      income.value = await getIncome(incomeId.value)
      store.dispatch('incomes/getAll')
    }

    const destroy = async () => {
      await destroyIncome(incomeId.value)
      mixpanel.track('Delete Income')
      store.dispatch('incomes/getAll')
      router.push({ name: 'income' })
    }

    onMounted(async () => {
      income.value = await getIncome(incomeId.value)
    })

    watch(
      () => cloneDeep(income.value),
      (current, previous) => {
        const params = diff(previous, current)

        // Don't do anything if income is loading for the first time
        if (isEmpty(previous)) {
          return
        }

        // Special treatment of attachments
        //   - If null, explicitly remove the attachment
        //   - If File, allow the attachment to be updated normally
        //   - If string (else), it means the attachment was likely recently
        //     updated and nothing new should happen
        if (params.hasOwnProperty('attachment')) {
          if (!params.attachment) {
            detach()
          }

          // If we're not updating the attachment, get rid of it!
          if (!(params.attachment instanceof File)) {
            delete params['attachment']
          }
        }

        if (!isEmpty(params)) {
          update(params)
        }
      }
    )

    return {
      income,
      destroy,
      close: () => router.push({ name: 'income' }),
      formatCurrency,
      formatDate,
    }
  }
}
</script>
