<template>
  <span
    class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium"
    :class="colorClass"
  >
    <slot></slot>
  </span>
</template>

<script>
import { computed, toRefs } from 'vue'

export default {
  props: ['color'],
  setup(props) {
    const { color } = toRefs(props)

    return {
      colorClass: computed(() => [`bg-${color.value}-100`, `text-${color.value}-800`])
    }
  }
}
</script>
