import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import mixpanel from '@/utils/mixpanel'
import Dashboard from '@/views/Dashboard'

import sign_in from './sign_in'
import sign_up from './sign_up'
import stays from './stays'
import incomes from './incomes'
import expenses from './expenses'
import reports from './reports'

const routes = [
  {
    path: '/',
    component: Dashboard,
    redirect: { name: 'stays' },
    meta: {
      auth: true,
    },
    children: [
      stays,
      incomes,
      expenses,
      reports,
    ],
  },
  sign_up,
  sign_in,
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// Add mixpanel tracking for meta.track
router.afterEach((to, from) => {
  if (to.meta.track) {
    mixpanel.track(to.meta.track, to.params)
  }
})

// Require authentication if meta.auth
router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.meta.auth)) {
		if (!store.getters['auth/isAuthenticated']) {
			next({ name: 'sign_in' })
			return
		}
	}
	next()
})

export default router
