<template>
  <div
    class="amo-selectable py-4 px-4 sm:px-6 sm:py-6 sm:flex sm:justify-between sm:items-center"
    tabindex="0"
    @click="show(stay)"
    @keydown.enter.stop="show(stay)"
  >
    <div class="sm:w-0 sm:flex-1">
      <h2 class="text-sm font-medium text-gray-900">
        {{ stay.name }}
      </h2>
      <p class="flex mt-1 text-sm text-gray-500 overflow-hidden overflow-ellipsis">
        <p>
          <time :datetime="stay.starts_on">{{ formattedStartsOn }}</time>
          -
          <time :datetime="stay.ends_on">{{ formattedEndsOn }}</time>
        </p>
      </p>
    </div>

    <div class="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
      <template v-if="stay.classification === 'personal'">
        <Badge color="yellow">Personal</Badge>
      </template>
      <template v-else-if="stay.classification === 'maintenance'">
        <Badge color="blue">Maintenance</Badge>
      </template>
      <template v-else-if="stay.classification === 'rental'">
        <Badge :color="netColor">{{ formattedNet }}</Badge>
      </template>
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'

import Badge from '@/components/Badge'
import { formatDateExt, formatCurrency } from '@/utils/helpers'
import api from '@/api'

export default {
  components: {
    Badge,
  },
  props: ['stay'],
  setup(props) {
    const { stay } = toRefs(props)
    const router = useRouter()

    const netColor = computed(() => {
      if (stay.value.net > 0) {
        return 'green'
      } else if (stay.value.net < 0) {
        return 'red'
      } else {
        return 'gray'
      }
    })

    const show = (stay) => {
      router.push({
        name: 'showStay',
        params: {
          stayId: stay.id,
        }
      })
    }

    return {
      formattedStartsOn: computed(() => formatDateExt(stay.value.starts_on)),
      formattedEndsOn: computed(() => formatDateExt(stay.value.ends_on)),
      formattedNet: computed(() => formatCurrency(stay.value.net)),
      netColor,
      show,
    }
  }
}
</script>
