import { createStore, createLogger } from 'vuex'

import auth from './modules/auth'
import panel from './modules/panel'
import account from './modules/account'
import incomes from './modules/incomes'
import expenses from './modules/expenses'
import categories from './modules/categories'
import stays from './modules/stays'
import dates from './modules/dates'

const debug = process.env.NODE_ENV !== 'production'

const state = () => {
  return {}
}

const getters = {}
const actions = {}
const mutations = {}
const plugins = []

if (debug) {
  plugins.push(createLogger())
}

export default createStore({
  state,
  getters,
  actions,
  mutations,
  plugins,
  modules: {
    auth,
    panel,
    account,
    incomes,
    expenses,
    categories,
    stays,
    dates,
  },
  strict: debug,
})
