const state = () => ({
  open: false,
})

const getters = {}

const actions = {}

const mutations = {
  open(state) {
    state.open = true
  },
  close(state) {
    state.open = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
