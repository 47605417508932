<template>
  <div class="min-h-screen bg-gray-100">
    <div class="bg-gray-800">
			<NavBar />
      <header class="py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <router-view name="Header"></router-view>
        </div>
      </header>
    </div>

    <main>
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useAccountSubscriber } from '@/composables/account'

import NavBar from '@/components/NavBar'
import Panel from '@/components/Panel'
import mixpanel from '@/utils/mixpanel'

export default {
  components: {
		NavBar,
    Panel,
  },
  setup() {
    const store = useStore()

    useAccountSubscriber(account => {
      const { user } = account
      mixpanel.identify(user.id)
      mixpanel.people.set({
        '$email': user.email,
      })
    })

    onMounted(() => {
      store.dispatch('account/get')
    })

    return {}
  }
}
</script>
