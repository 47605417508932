<template>
	<div class="flex flex-col">
    <div class="-my-2 sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="table-auto min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="amo-cell-heading">
                  Date
                </th>
                <th scope="col" class="amo-cell-heading">
                  Payee
                </th>
                <th scope="col" class="amo-cell-heading hidden md:table-cell">
                  Category
                </th>
                <th scope="col" class="amo-cell-heading hidden md:table-cell">
                  Memo
                </th>
                <th scope="col" class="amo-cell-heading text-right">
                  Amount
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <ExpenseItem v-for="(expense, i) in expenses" :key="expense.id" :expense="expense" />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExpenseItem from './ExpenseItem'

export default {
  components: {
    ExpenseItem,
  },
  props: ['expenses'],
}
</script>
