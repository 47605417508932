<template>
  <div class="md:flex md:items-center md:justify-between">
    <div class="flex-1 min-w-0">
      <h1 class="amo-h1">
        Reports
      </h1>
    </div>
  </div>
</template>

<script>
export default {}
</script>
