<template>
  <div class="my-10 flex justify-end">
    <DateRangeInput />
  </div>

  <Overview :expenses="expenses" :loaded="loaded" />

  <ExpensesList :expenses="expenses" v-if="expenses.length > 0" />

  <EmptyState
    v-if="loaded && expenses.length === 0"
    title="No Expenses"
    description="Get started by adding expenses and mileage to maximize your deductions."
    :to="{ name: 'addExpense' }"
    label="Add Expense"
  />

  <Panel @close="closePanel"><router-view></router-view></Panel>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import DateRangeInput from '@/components/DateRangeInput'
import Panel from '@/components/Panel'
import Overview from './Overview'
import ExpensesList from './ExpensesList'
import EmptyState from '@/components/EmptyState'
import { useDatesSubscriber } from '@/composables/dates'

export default {
  components: {
    DateRangeInput,
    Overview,
    ExpensesList,
    EmptyState,
    Panel,
  },
	setup() {
    const store = useStore()
    const router = useRouter()

    useDatesSubscriber(store => {
      store.dispatch('expenses/getAll')
    })

    const expenses = computed(() => store.state.expenses.all)
    const loaded = computed(() => store.state.expenses.loaded)

    onMounted(() => store.dispatch('expenses/getAll'))

    return {
      expenses,
      loaded,
      closePanel: () => router.push({ name: 'expenses' }),
    }
  }
}
</script>
