import presets from '@/utils/presets'

const defaultPreset = presets.find(p => p.name === 'Current Year')

const state = () => ({
  preset: defaultPreset.name,
  start: defaultPreset.start(),
  end: defaultPreset.end(),
})

const getters = {}

const actions = {}

const mutations = {
  setPreset(state, { name }) {
    const preset = presets.find(p => p.name === name)
    state.preset = preset.name
    state.start = preset.start()
    state.end = preset.end()
  },
  setCustom(state, { start, end }) {
    state.preset = null

    if (start) {
      state.start = start
    }

    if (end) {
      state.end = end
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
